import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/Layout'
import { Formik, Form } from 'formik'
import queryString from 'query-string'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { isBrowser } from '../components/Auth'
import { getUsers } from '../services/api/users'
import { getRemission, putRemission } from '../services/api/remissions'
import { Context } from '../Context'

export default function ProfilePage (props) {
  const { isAuth } = useContext(Context)

  if (isBrowser() && !isAuth) {
    if (isBrowser()) {
      window.location.href = '/'
    }

    return (
      <Layout withoutHeader className='page-users'>
        Cargando...
      </Layout>
    )
  }

  const [remission, setRemission] = useState({
    receiver: 0
  })
  const [receptors, setReceptors] = useState([])
  const params = queryString.parse(props.location.search)

  useEffect(() => {
    if (!params.remission_id) {
      window.location.href = '/dashboard/'
      return
    }

    getRemission(params.remission_id)
      .then(res => {
        if (res.data.remissions.length > 0) {
          setRemission(res.data.remissions[0])
        }
      })
      .catch(err => {
        console.dir(err)
        if (err.response) {
          toast.error(err.response.data.message)
        }
      })

    // carga receptores
    getUsers({ role_id: 3 })
      .then(res => {
        setReceptors(
          res.data.map(item => {
            return {
              value: item.id,
              label: `${item.name} ${item.name2} ${item.lastname}`
            }
          })
        )
      })
      .catch(err => {
        console.dir(err)
        if (err.response) {
          toast.error(err.response.data.message)
        }
      })
  }, [])

  const onSubmit = values => {
    putRemission({ ...remission, receiver: values.receptor })
      .then(() => {
        toast.success('Se ha reasignado receptor de remisión correctamente.')
      })
      .catch(err => {
        console.dir(err)
        if (err.response) {
          toast.error(err.response.data.message)
        }
      })
  }

  return (
    <Layout className='page-users'>
      <div
        style={{
          maxWidth: '600px',
          margin: '3rem auto'
        }}
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={{ receptor: remission.receiver }}
          render={({ values, setFieldValue }) => (
            <Form>
              <div>
                <label style={{ color: 'white', margin: '1rem 0' }}>
                  Receptores:
                </label>
                <Select
                  options={receptors}
                  placeholder='Receptores'
                  className='select'
                  classNamePrefix='select'
                  value={receptors.find(item => {
                    return item.value === values.receptor
                  })}
                  onChange={e => setFieldValue('receptor', e.value)}
                />
              </div>

              <div className='text-center' style={{ marginTop: '3rem' }}>
                <button
                  type='submit'
                  style={{
                    fontSize: '1rem',
                    backgroundColor: 'white',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '0.25rem 1rem',
                    color: 'var(--color2)',
                    fontWeight: 'bold'
                  }}
                >
                  GUARDAR
                </button>
              </div>
            </Form>
          )}
        />
      </div>
    </Layout>
  )
}
